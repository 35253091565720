import { defineStore } from 'pinia';
import axios from 'axios'; 

export const useRegisterSettingsStore = defineStore('registerSettings', {
  id: 'registerSettings',
  state: () => ({
    registerName: '',
    printerName: '',
    openRegisters: [], 
    selectedRegister: JSON.parse(localStorage.getItem('selectedRegister')) || null,
    registerName: localStorage.getItem('registerName') || '',
  }),
  actions: {
    setSelectedRegister(register) {
      this.selectedRegister = register;
      this.registerName = register ? register.name : '';
      localStorage.setItem('selectedRegister', JSON.stringify(register));
      this.setRegisterSettings(this.registerName, this.printerName);
    },
    setRegisterSettings(registerName, printerName) {
      this.registerName = registerName;
      this.printerName = printerName;
      localStorage.setItem('registerSettings', JSON.stringify({ registerName, printerName }));
    },
    async loadRegisterSettings() {
      const settings = localStorage.getItem('registerSettings');
      const storedSelectedRegister = localStorage.getItem('selectedRegister');
    
      if (settings) {
        const { registerName, printerName } = JSON.parse(settings);
        this.registerName = registerName || ''; // Ensure it's an empty string if undefined
        this.printerName = printerName || ''; // Ensure it's an empty string if undefined
      }
    
      if (storedSelectedRegister) {
        this.selectedRegister = JSON.parse(storedSelectedRegister);
      }
    
      // Load the register data if registerName is available
      if (this.registerName) {
        await this.loadOpenRegisters();
        const openRegister = this.openRegisters.find(register => register.registerName === this.registerName);
        if (openRegister) {
          await this.loadOpenRegister(openRegister._id); // Load the selected register
        }
      }
    },
    clearRegisterSettings() {
      this.registerName = null;
      this.printerName = null;
      localStorage.removeItem('registerSettings');
    },
    async loadOpenRegisters() {
      try {
        const response = await axios.get('/api/register/state/open'); // Adjust the endpoint as needed
        if (response.data.success) {
          this.openRegisters = response.data.records.map(register => {
            // Ensure the date is in the "DD-MM-YYYY" format
            if (register.date) {
              register.date = this.formatDate(register.date); // Format the date if needed
            }
            return register;
          });
          
          // Log the loaded open registers
          console.log('Loaded open registers:', this.openRegisters);
        } else {
          console.error('Failed to load open registers: ', response.data.message);
        }
      } catch (error) {
        console.error('Failed to load open registers:', error);
      }
    },
    async loadOpenRegister(registerId) {
      try {
        const response = await axios.get(`/api/register/${registerId}`); // Adjust the endpoint as necessary
        if (response.data.success) {
          this.selectedRegister = response.data.register; // Ensure this is set correctly
        } else {
          console.error("Failed to load register:", response.data.message);
          this.selectedRegister = null; // Reset selected register on error
        }
      } catch (error) {
        console.error("Failed to load register:", error);
        this.selectedRegister = null; // Reset selected register on error
      }
    },
        // New method to format date strings
        formatDate(dateString) {
          // Assuming dateString is in "DD-MM-YYYY" format, you can return it directly
          return dateString; // Modify this if you need to parse or format differently
        },
        // New reset method
        reset() {
          this.registerName = '';
          this.printerName = '';
          this.openRegisters = [];
          this.selectedRegister = null;
          localStorage.removeItem('registerSettings'); // Clear local storage as well
          console.log('Register store has been reset to initial state.');
        },
  },
});