import { defineStore } from 'pinia';
import { io } from 'socket.io-client';
import axios from 'axios';
import { useRegisterSettingsStore } from './registerStore'; // Add this import

const socket = io(process.env.VUE_APP_SOCKET_URL || 'http://localhost:4000');

export const useCartStore = defineStore('cart', {
  state: () => ({
    items: [],
    clientInfo: {
      firstName: '',
      lastName: '',
      email: '',
      flightOrShip: '',
      ticketOrCabin: '',
      departureDate: '',
      payment_method: '',
      customsOffice: ''
    },
    orderNumber: '',
    cartId: null,
    subtotal: 0,
    taxAmount: 0, 
    totalAmount: 0,
    Price: 0,
    market: '',
    registerName: '',
    registerSession: '',
    barMode: false,
    paymentCurrency: 'NZD',
    convertedExactAmountStripeNZD: '',
    paymentIntentId: null,
    taxRate: 0.15,
    selectedReader: null,
    discountApplied: false,
    itemToEdit: null,
    isEditPriceModalOpen: false,
    firebaseUserId: null,
    exchangeRate: 0, // Add exchange rate property
    exchangeRateStripe: 0, // Add exchange rate property
    tabReference: null,
  }),
  actions: {
    async addOrderToCart(order) {
      // Check if the order is already in the cart
      const existingOrderIndex = this.items.findIndex(item => item.orderId === order._id);
      
      if (existingOrderIndex !== -1) {
        console.log('Order already in cart:', order.number);
        return;
      }
    
      // Add each line item from the order to the cart
      for (const item of order.line_items) {
        let vatAmount = 0;
        let priceExcludingVat = item.price;
    
        // Check if VAT is already included in the price
        const vatIncluded = item.meta_data.find(m => m.key === 'vat_included')?.value === 'true';
    
        if (vatIncluded) {
          // If VAT is included, calculate the VAT amount
          const vatRate = 0.15; // Assuming 15% VAT rate, adjust if necessary
          vatAmount = (item.price * vatRate) / (1 + vatRate);
          priceExcludingVat = item.price - vatAmount;
        } else {
          // If VAT is not included, calculate it based on the price
          vatAmount = item.price * 0.15; // Assuming 15% VAT rate, adjust if necessary
        }
    
        this.items.push({
          productId: item.product_id,
          orderId: order._id,
          quantity: item.quantity,
          price: priceExcludingVat,
          vat: vatAmount,
          ProductDescription: item.name,
          Format: item.meta_data.find(m => m.key === 'Format')?.value || '',
          ProductCode: item.sku,
          market: item.meta_data.find(m => m.key === 'Market')?.value || 'DutyPaid',
          // Add any other fields you need
        });
      }
    
      // Update client info if necessary
      if (!this.clientInfo.firstName && order.billing) {
        this.updateClientInfo({
          firstName: order.billing.first_name,
          lastName: order.billing.last_name,
          email: order.billing.email,
          // Add other fields as necessary
        });
      }
    
      // Recalculate totals
      this.updateSubtotal();
    
      // Update the cart state
      this.updateCartState();
    
      // Update exchange rates
      await this.updateExchangeRate();
      await this.updateExchangeRateStripe();
    },
    async updateOrderStatus(orderId, status, paymentMethod) {
      try {
        const response = await axios.put(`/api/orders/${orderId}`, {
          status: status,
          payment_method: paymentMethod
        });
        return response.data;
      } catch (error) {
        console.error('Error updating order status:', error);
        throw error;
      }
    },
    async loadOrderIntoCart(order) {
      this.clearCart();

      await this.addOrderToCart(order);


      // Load order details into the cart
      this.items = order.line_items.map(item => ({
        productId: item.product_id,
        ProductCode: item.sku,
        ProductDescription: item.name,
        Name: item.name,
        price: parseFloat(item.price),
        quantity: item.quantity,
        market: item.market || 'DutyPaid', // Assuming 'DutyPaid' as default if not specified
        Format: item.format || '',
        batch: item.batch || null,
        vatAmount: parseFloat(item.total_tax) / item.quantity
      }));

      // Update client info
      this.updateClientInfo({
        firstName: order.billing.first_name,
        lastName: order.billing.last_name,
        email: order.billing.email,
        flightOrShip: order.flightOrShip || '',
        ticketOrCabin: order.ticketOrCabin || '',
        departureDate: order.departureDate || '',
        customsOffice: order.customsOffice || ''
      });

      // Set other order details
      this.tabReference = order.tabReference || null;
      this.orderNumber = order.number;

        // Update totals
  this.updateSubtotal();

  // Update exchange rates
  await this.updateExchangeRate();
  await this.updateExchangeRateStripe();

  // Log updated cart state
  console.log('Updated cart state:', {
    items: this.items,
    clientInfo: this.clientInfo,
    subtotal: this.subtotal,
    taxAmount: this.taxAmount,
    totalAmount: this.totalAmount,
    exchangeRate: this.exchangeRate,
    exchangeRateStripe: this.exchangeRateStripe
  });
},
    setBarMode(mode) {
      this.barMode = mode;
    },
    setSelectedReader(reader) {
      this.selectedReader = reader;
    },
    async setTabReference(tableNumber, orderNumber) {
      console.log(`setTabReference called with tableNumber: ${tableNumber}, orderNumber: ${orderNumber}`);
      this.tabReference = tableNumber.toString();
      
      // Update the order in the database
      try {
        console.log(`Attempting to update order ${orderNumber} with tabReference ${this.tabReference}`);
        const response = await axios.put(`/api/orders/update-tab-reference/${orderNumber}`, {
          tabReference: this.tabReference
        });
        
        if (response.data) {
          console.log('Order updated with tab reference:', response.data);
        } else {
          console.error('Failed to update order with tab reference: Response data is empty');
        }
      } catch (error) {
        console.error('Error updating order with tab reference:', error);
        console.error('Error details:', error.response ? error.response.data : 'No response data');
      }
    },
    updateRegisterName() {
      const registerSettings = useRegisterSettingsStore();
      this.registerName = registerSettings.registerName;
      this.registerSession = registerSettings.registerSession;
    },
    setTaxRate(newTaxRate) {
      this.taxRate = newTaxRate;
      this.updateSubtotal(); // Recalculate totals when tax rate changes
    },
    addToCart(product) {
      //console.log("Received in store:", product);  // Log the product details received in the store
      const existingProduct = this.items.find(item => item.productId === product.productId);
      if (existingProduct) {
        existingProduct.quantity += 1;
      } else {
        const productToAdd = {
          ...product,
          Price: { Value: Math.floor(product.price) },
          Name: product.ProductDescription, // Ensure the Name is set from ProductDescription
          productId: product.productId, // Ensure productId is set
          ProductCode: product.productId,
          quantity: 1,
          vatAmount: this.calculateVatAmount(product.price, product.market) // Pass market to calculate VAT
        };
        this.items.push(productToAdd);
      }
      this.updateSubtotal();
      this.updateCartState();
      this.updateExchangeRate();
      this.updateExchangeRateStripe()
    },
    loadCartFromTab(tabCart) {
      this.items = tabCart.map(item => ({ ...item }));
      this.updateSubtotal();
    },
    calculateVatAmount(price, market) {
      if (market === 'DutyFree') {
        return '0.00'; // No VAT for duty-free items
      }
      const taxRate = this.taxRate; // Access the tax rate from the store
      return (price * taxRate).toFixed(2); // Calculate VAT and format to 2 decimal places
    },
    clearCart() {
      this.items = [];
      this.subtotal = 0;
      this.taxAmount = 0;
      this.totalAmount = 0;
      this.tabReference = null;
      this.clientInfo = {
        firstName: '',
        lastName: '',
        email: '',
        flightOrShip: '',
        ticketOrCabin: '',
        departureDate: '',
        payment_method: '',
        customsOffice: ''
        
      };
      this.discountApplied = false; // Reset the discountApplied flag
    },
    updateClientInfo(info) {
      this.clientInfo = { ...this.clientInfo, ...info };
      this.updateCartState();
    },
    addToExistingCart(productId) {
      const itemIndex = this.items.findIndex(item => item.productId === productId);
      if (itemIndex !== -1) {
        this.items[itemIndex].quantity += 1;
      }
      this.updateSubtotal();
      this.updateCartState();
    },
    removeFromCart(productId) {
      const itemIndex = this.items.findIndex(item => item.productId === productId);
      if (itemIndex !== -1) {
        if (this.items[itemIndex].quantity > 1) {
          this.items[itemIndex].quantity -= 1;
        } else {
          this.items.splice(itemIndex, 1);
        }
      }
      this.updateSubtotal();
      this.updateCartState();
    },
    updateCartState() {
      socket.emit('updateCart', this.$state);
    },
    openPriceEditModal(item) {
      console.log("Opening price edit modal for:", item); // Log the item being edited
      this.itemToEdit = item; // Set the item to be edited
      this.isEditPriceModalOpen = true; // Open the modal
  },
  async fetchExchangeRate() {
    try {
      const response = await axios.get('https://v6.exchangerate-api.com/v6/04321f6498b294c03ffbb425/latest/VUV'); // Your API key is already in the URL
      this.exchangeRate = response.data.conversion_rates.AUD; // Store the AUD rate
      console.log('Fetched Exchange Rate (AUD):', this.exchangeRate); // Log the fetched exchange rate
    } catch (error) {
      console.error('Error fetching exchange rate:', error);
    }
  },
  async fetchExchangeRate() {
    try {
      const response = await axios.get('https://v6.exchangerate-api.com/v6/04321f6498b294c03ffbb425/latest/VUV'); // Your API key is already in the URL
      this.exchangeRate = response.data.conversion_rates.AUD; // Store the AUD rate
      console.log('Fetched Exchange Rate (AUD):', this.exchangeRate); // Log the fetched exchange rate
    } catch (error) {
      console.error('Error fetching exchange rate:', error);
    }
  },
  async fetchExchangeRateStripe() {
    try {
      const response = await axios.get('https://v6.exchangerate-api.com/v6/04321f6498b294c03ffbb425/latest/VUV'); // Your API key is already in the URL
      this.exchangeRateStripe = response.data.conversion_rates.NZD; // Store the AUD rate
      console.log('Fetched Exchange Rate Stripe (NZD):', this.exchangeRateStripe); // Log the fetched exchange rate
    } catch (error) {
      console.error('Error fetching exchange rate:', error);
    }
  },
  updateExchangeRate() {
    // Check if exchange rate is already set, if not fetch it
    if (!this.exchangeRate) {
      this.fetchExchangeRate();
    }
  },
  updateExchangeRateStripe() {
    // Check if exchange rate is already set, if not fetch it
    if (!this.exchangeRateStripe) {
      this.fetchExchangeRateStripe();
    }
  },
  closePriceEditModal() {
      console.log("Closing price edit modal for:", this.itemToEdit); // Log the item being cleared
      this.itemToEdit = null; // Clear the item being edited
      this.isEditPriceModalOpen = false; // Close the modal
  },
  updateItemPrice(newPriceWithVAT) {
    if (this.itemToEdit) {
        const itemIndex = this.items.findIndex(item => item.productId === this.itemToEdit.productId);
        if (itemIndex !== -1) {
            // Calculate the price without VAT
            const priceWithoutVAT = (newPriceWithVAT / (1 + this.taxRate)).toFixed(2); // Calculate price without VAT

            // Update the item's price to the price without VAT
            this.items[itemIndex].price = parseFloat(priceWithoutVAT); 

            // Recalculate VAT amount for the updated item
            this.items[itemIndex].vatAmount = this.calculateVatAmount(this.items[itemIndex].price, this.items[itemIndex].market);

            // Recalculate subtotal and other totals
            this.updateSubtotal(); // Ensure totals are updated
        }
        this.itemToEdit = null; // Clear the item after editing
    }
},
    applyStaffDiscount() {
      this.discountApplied = !this.discountApplied; // Toggle the discountApplied state
  
      if (this.discountApplied) {
          console.log("Applying staff discount..."); // Log when the discount is applied
      } else {
          console.log("Removing staff discount..."); // Log when the discount is removed
      }
  
      // Force an update of the subtotal to account for the discount
      this.updateSubtotal(); // Call this to recalculate totals
  },
  updateSubtotal() {
    // Calculate subtotal based on items
    this.subtotal = this.items.reduce((sum, item) => {
        return sum + item.price * item.quantity;
    }, 0);

    // If discount is applied, adjust the subtotal
    if (this.discountApplied) {
        const discountRate = 0.15; // Assuming a 15% discount
        const discountAmount = Math.round(this.subtotal * discountRate);
        this.subtotal -= discountAmount; // Apply discount to subtotal
    }

    this.taxAmount = this.items.reduce((sum, item) => {
        return (item.market === 'DutyPaid' || item.market === 'Retail' || item.market === 'Apparel' || item.market === 'Bar') 
            ? sum + (item.price * this.taxRate) * item.quantity 
            : sum;
    }, 0);

    this.totalAmount = this.subtotal + this.taxAmount;

    // Round all amounts to the nearest whole number
    this.subtotal = Math.round(this.subtotal);
    this.taxAmount = Math.round(this.taxAmount);
    this.totalAmount = Math.round(this.totalAmount);

    // Log the updated values
    console.log('Updated Subtotal:', this.subtotal);
    console.log('Updated Tax Amount:', this.taxAmount);
    console.log('Updated Total Amount:', this.totalAmount);

      // Emit the updated cart state
    this.updateCartState();
},
    initializeCartState(cartState) {
      this.$patch(cartState);
    },
    async placeOrder(selectedReader) {
      try {
        const orderNumber = this.generateOrderNumber();
        console.log('Generated Order Number:', orderNumber);
    
        let transactionId = null;
        let datePaid = null;
    
        // Handle Stripe payment if necessary
        if (this.clientInfo.payment_method === 'Stripe') {
          const paymentIntentResponse = await this.createPOSPaymentIntent(orderNumber);
          if (!paymentIntentResponse || !paymentIntentResponse.id) {
            throw new Error('Failed to create PaymentIntent');
          }
          transactionId = paymentIntentResponse.id;
          datePaid = new Date();
    
           // Process the payment with the selectedReader
      const processPaymentResponse = await this.processPayment(selectedReader);
      if (!processPaymentResponse || processPaymentResponse.error) {
        throw new Error('Failed to process payment');
      }
    }
    
        this.items.forEach(item => {
          console.log('Batch Number for Product', item.ProductCode, ':', item.batch);
        });
    
        const orderData = {
          id: orderNumber,
          parent_id: '',
          number: orderNumber,
          order_key: '',
          created_via: 'POS83',
          version: '1.0',
          status: this.clientInfo.payment_method === 'Table Service' ? 'TableService' : 'Completed',
          dutyFree: true,
          Unleashed: false,
          Woo: false,
          POS83: true,
          flightOrShip: this.clientInfo.flightOrShip,
          ticketOrCabin: this.clientInfo.ticketOrCabin,
          departureDate: this.clientInfo.departureDate,
          customsOffice: this.clientInfo.customsOffice,
          tabReference: this.tabReference,
          currency: 'VUV',
          date_created: new Date(),
          date_created_gmt: new Date(),
          date_modified: new Date(),
          date_modified_gmt: new Date(),
          discount_total: '0',
          discount_tax: '0',
          shipping_total: '0',
          shipping_tax: '0',
          cart_tax: this.tax.toString(),
          total: this.total.toString(),
          total_tax: this.tax.toString(),
          prices_include_tax: false,
          customer_id: this.clientInfo.customerId || 0,
          customer_ip_address: '',
          customer_user_agent: '',
          customer_note: '',
          firebaseUserId: this.firebaseUserId,
          billing: {
            first_name: this.clientInfo.firstName || '',
            last_name: this.clientInfo.lastName || '',
            company: this.clientInfo.company || '',
            address_1: this.clientInfo.address1 || '',
            address_2: this.clientInfo.address2 || '',
            city: this.clientInfo.city || '',
            state: this.clientInfo.state || '',
            postcode: this.clientInfo.postcode || '',
            country: this.clientInfo.country || '',
            email: this.clientInfo.email || '',
            phone: this.clientInfo.phone || ''
          },
          shipping: {
            first_name: this.clientInfo.firstName || '',
            last_name: this.clientInfo.lastName || '',
            company: this.clientInfo.company || '',
            address_1: this.clientInfo.address1 || '',
            address_2: this.clientInfo.address2 || '',
            city: this.clientInfo.city || '',
            state: this.clientInfo.state || '',
            postcode: this.clientInfo.postcode || '',
            country: this.clientInfo.country || ''
          },
          payment_method: this.clientInfo.payment_method,
          payment_method_title: '',
          transaction_id: transactionId,
          date_paid: datePaid,
          date_paid_gmt: datePaid ? datePaid.toUTCString() : null,
          date_completed: null,
          date_completed_gmt: null,
          cart_hash: '',
          meta_data: [],
          line_items: this.items.map((item, index) => ({
            id: index + 1,
            name: item.Name,
            product_id: item.productId,
            quantity: item.quantity,
            tax_class: '',
            subtotal: (item.price / (1 + this.taxRate)).toString(),
            subtotal_tax: ((item.price - (item.price / (1 + this.taxRate))) * item.quantity).toString(),
            total: item.price.toString(),
            total_tax: ((item.price - (item.price / (1 + this.taxRate))) * item.quantity).toString(),
            taxes: [],
            meta_data: [],
            sku: item.ProductCode || '',
            price: item.price.toString(),
            market: item.market,
            batch: {
              Guid: item.batch?.Guid || '',
              Number: item.batch?.Number || '',
              Quantity: item.batch?.Quantity || 0
            },
            format: item.Format
          })),
          tax_lines: [
            {
              rate_code: 'VAT',
              rate_id: 1,
              label: 'VAT',
              compound: false,
              tax_total: this.tax.toString(),
              shipping_tax_total: '0',
              meta_data: []
            }
          ],
          shipping_lines: [],
          fee_lines: [],
          coupon_lines: [],
          refunds: [],
          registerName: this.registerName,
          registerSession: this.registerSession
        };
    
        const response = await axios.post('/api/orders', orderData);
        console.log('API response:', response);
    
        // Update register record
        await this.updateRegisterRecord(orderNumber);
  
        // Handle stock adjustments
        await this.handleStockAdjustments(response.data.id);
    
        return { orderId: response.data.id };
      } catch (error) {
        console.error('Error placing order:', error);
        throw error;
      }
    },
    async updateRegisterRecord(orderNumber) {
      const registerOrderData = {
        orderNumber: orderNumber,
        registerName: this.registerName,
        clientName: `${this.clientInfo.firstName} ${this.clientInfo.lastName}`,
        totalAmount: this.total,
        paymentMethod: this.clientInfo.payment_method,
        status: this.clientInfo.payment_method === 'Table Service' ? 'TableService' : 'Completed',
        items: this.items.map(item => ({
          product_id: item.productId,
          quantity: item.quantity,
          price: item.price,
          ProductCode: item.ProductCode,
          SKU: item.ProductCode
        }))
      };
      console.log('Register Order Data:', registerOrderData);
  
  try {
    const registerResponse = await axios.post('/api/register/place-order', registerOrderData);
    console.log('Register update response:', registerResponse.data);
    return registerResponse.data;
  } catch (error) {
    console.error('Error updating register record:', error.response ? error.response.data : error.message);
    throw error;
  }
},
    async handleStockAdjustments(orderId) {
      const stockAdjustments = {};
      const combinedItems = this.items;

      combinedItems.forEach(item => {
        const warehouse = item.market === 'DutyFree' ? 'CCA' : 'W1';

        if (!stockAdjustments[warehouse]) {
          stockAdjustments[warehouse] = {
            Warehouse: { WarehouseCode: warehouse },
            AdjustmentDate: new Date().toISOString(),
            AdjustmentReason: 'Sale Completed',
            Status: 'Completed',
            StockAdjustmentLines: []
          };
        }

        const newQuantity = item.quantity;
        const newActualValue = 0;

        let batchNumbers = [];
        if (item.batch && item.batch.Number && item.batch.Quantity !== undefined) {
          batchNumbers.push({
            Number: item.batch.Number,
            Quantity: item.batch.Quantity
          });
        }

        stockAdjustments[warehouse].StockAdjustmentLines.push({
          LineNumber: stockAdjustments[warehouse].StockAdjustmentLines.length + 1,
          Product: { ProductCode: item.ProductCode },
          BatchNumbers: batchNumbers,
          NewQuantity: newQuantity,
          NewActualValue: newActualValue,
          Comments: `${orderId}`
        });
      });

      for (const warehouse in stockAdjustments) {
        try {
          const adjustmentData = stockAdjustments[warehouse];
          console.log(`Sending stock adjustment for warehouse ${warehouse}:`, adjustmentData);
          
          const response = await axios.post('/api/orders/create-stock-adjustment', { adjustmentData, orderId });
          
          console.log(`Stock adjustment for warehouse ${warehouse} created successfully:`, response.data);
        } catch (error) {
          console.error(`Error creating stock adjustment for warehouse ${warehouse}:`, error);
        }
      }
    },
    
    generateOrderNumber() {
      const now = new Date();
      const year = now.getFullYear().toString().slice(-2);
      const dayOfYear = Math.floor((now - new Date(now.getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24);
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const seconds = String(now.getSeconds()).padStart(2, '0');
    
      let prefix;
    
      if (this.barMode) {
        prefix = '83BAR';
      } else {
        const hasDutyFreeItems = this.items.some(item => item.market === 'DutyFree');
        const hasDutyPaidItems = this.items.some(item => 
          item.market === 'DutyPaid' || item.market === 'Retail' || item.market === 'Apparel'
        );
    
        if (hasDutyFreeItems && hasDutyPaidItems) {
          prefix = '83MX'; // Mixed order (both duty-free and duty-paid items)
        } else if (hasDutyFreeItems) {
          prefix = '83DF'; // Duty-free order
        } else if (hasDutyPaidItems) {
          prefix = '83DP'; // Duty-paid order
        } else {
          prefix = '83UN'; // Unknown or other type of order
        }
      }
    
      return `${prefix}-${year}${dayOfYear}-${hours}${minutes}${seconds}`;
    },
    async createPOSPaymentIntent(orderNumber) {
      try {
        const amountToCharge = this.convertedExactAmountStripeNZD * 100; // Convert to cents
        
        console.log('Amount to charge (in cents):', amountToCharge);
        console.log('Original amount (NZD):', this.convertedExactAmountStripeNZD);
        console.log('Payment currency:', this.paymentCurrency);
    
        if (amountToCharge <= 0) {
          throw new Error('Invalid amount: The amount to charge must be greater than 0');
        }
        
        const metadata = {
          order_id: orderNumber,
          first_name: this.clientInfo.firstName || '',
          last_name: this.clientInfo.lastName || '',
          email: this.clientInfo.email || '',
        };

        const response = await axios.post('/api/stripe/create-pos-payment-intent', {
          amount: amountToCharge,
          currency: this.paymentCurrency,
          payment_method_types: ['card'],
          metadata: metadata,
        });
        console.log('PaymentIntent created:', response.data);
        
        if (response.data && response.data.id) {
          console.log('Setting paymentIntentId to:', response.data.id);
          this.paymentIntentId = response.data.id;
          console.log('paymentIntentId after setting:', this.paymentIntentId);
        } else {
          throw new Error('PaymentIntent ID is not returned from the API');
        }
    
        return response.data;
      } catch (error) {
        console.error('Error creating PaymentIntent:', error.response ? error.response.data : error.message);
        throw error;
      }
    },
    
    async processPayment(selectedReader) {
      console.log('Processing payment. Current state:', JSON.stringify(this.$state, null, 2));
      console.log('Payment Intent ID at processPayment:', this.paymentIntentId);
      console.log('Selected Reader ID:', selectedReader);
    
      if (!selectedReader) {
        console.error('Reader ID is not set');
        throw new Error('Reader ID is required to process payment');
      }
      if (!this.paymentIntentId) {
        console.error('Payment Intent ID is not set');
        throw new Error('Payment Intent ID is required to process payment');
      }
    
      const metadata = {
        first_name: this.clientInfo.firstName || '',
        last_name: this.clientInfo.lastName || '',
        email: this.clientInfo.email || '',
        order_number: this.orderNumber || '',
      };
    
      try {
        const response = await axios.post(`/api/stripe/readers/${selectedReader}/process-payment-intent`, {
          payment_intent: this.paymentIntentId,
          metadata: metadata,
        });
        console.log('Payment processed:', response.data);
    
        return response.data;
      } catch (error) {
        console.error('Error processing payment:', error);
        throw error;
      }
    }
  },
  getters: {
    tax: (state) => (state.subtotal * 0.15) / (1 + 0.15), // Example tax calculation
    total: (state) => state.subtotal + state.taxAmount,
    
    convertedExactAmountStripeNZD: (state) => {
      console.log('Calculating convertedExactAmountStripeNZD');
      console.log('Total Amount:', state.totalAmount);
      console.log('Exchange Rate Stripe:', state.exchangeRateStripe);
      
      const total = state.totalAmount * state.exchangeRateStripe;
      console.log('Calculated total in NZD:', total);
      
      const roundedTotal = Math.ceil(total);
      console.log('Rounded total:', roundedTotal);
      
      return roundedTotal;
    },
    getPaymentIntentId() {
      console.log('Current paymentIntentId:', this.paymentIntentId);
      return this.paymentIntentId;
    },
  },

});

socket.on('cartState', (cartState) => {
  const cartStore = useCartStore();
  cartStore.initializeCartState(cartState);
});