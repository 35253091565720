<template>
    <div class="min-h-full">
      <main class="bg-gray-50">
        <div class="p-4">
          <h2 class="text-lg font-medium text-gray-900">Client Information</h2>
        <button @click="showModal = true" class="mt-2 bg-blue-500 text-white px-4 py-2 rounded">Select Register</button>

          <!-- Modal for Register Selection -->
          <div v-if="showModal" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div class="bg-white p-4 rounded shadow-lg">
            <h3 class="text-lg font-medium">Select Cash Register</h3>
            <ul>
              <li v-for="register in openRegisters" :key="register._id">
                <button @click="selectRegister(register)" class="block w-full text-left p-2 hover:bg-gray-200">{{ register.registerName }}</button>
              </li>
            </ul>
            <button @click="showModal = false" class="mt-4 bg-gray-300 px-4 py-2 rounded">Close</button>
          </div>
        </div>
          <form class="space-y-4">
            <div>
              <label for="firstName" class="block text-sm font-medium text-gray-700">First Name</label>
              <input id="firstName" v-model="firstName" type="text" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md" @input="startEditing" @blur="stopEditing" />
            </div>
            <div>
              <label for="lastName" class="block text-sm font-medium text-gray-700">Last Name</label>
              <input id="lastName" v-model="lastName" type="text" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md" @input="startEditing" @blur="stopEditing" />
            </div>
            <div>
              <label for="email" class="block text-sm font-medium text-gray-700">Email</label>
              <input id="email" v-model="clientEmail" type="email" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md" @input="startEditing" @blur="stopEditing" />
            </div>
          </form>
  
          <h2 class="text-lg font-medium text-gray-900 mt-6">Cart</h2>
          <ul class="mt-4 space-y-2">
            <li v-for="item in cartStore.items" :key="item.productId" class="flex justify-between">
              <span>{{ item.ProductCode }} - {{ item.ProductDescription }}</span>
              <span>{{ item.quantity }} x {{ Math.floor(item.price) }} VT</span>
            </li>
          </ul>
          <div class="mt-4">
            <div class="flex justify-between">
              <span class="text-sm text-gray-600">Subtotal (excl. VAT)</span>
              <span class="text-sm font-medium text-gray-900">{{ cartStore.subtotal }} VT</span>
            </div>
            <div class="flex justify-between">
              <span class="text-sm text-gray-600">VAT (15%)</span>
              <span class="text-sm font-medium text-gray-900">{{ cartStore.taxAmount }} VT</span>
            </div>
            <div class="flex justify-between border-t border-gray-200 pt-4">
              <span class="text-base font-medium text-gray-900">Order total (incl. VAT)</span>
              <span class="text-base font-medium text-gray-900">{{ cartStore.totalAmount }} VT</span>
            </div>
          </div>
        </div>
      </main>
    </div>
  </template>
  
  <script setup>
  import { ref, watch, watchEffect, onMounted } from 'vue';
  import { useCartStore } from '@/stores/cart';
  import { useRegisterSettingsStore } from '@/stores/registerStore'; // Import the register store
  import { storeToRefs } from 'pinia';
  import axios from 'axios';
  import debounce from 'debounce';
  
  const cartStore = useCartStore();
  const registerStore = useRegisterSettingsStore(); // Use the register store
  const { items, subtotal, taxAmount, totalAmount, clientInfo } = storeToRefs(cartStore);
  const { openRegisters } = storeToRefs(registerStore); // Get open registers from the register store

  
  const firstName = ref(clientInfo.value.firstName);
  const lastName = ref(clientInfo.value.lastName);
  const clientEmail = ref(clientInfo.value.email);
  const showModal = ref(false); // State for modal visibility

  // Fetch open registers from the store
  onMounted(() => {
    registerStore.loadOpenRegisters(); 
  });
    

// Function to select a register
const selectRegister = (register) => {
  registerStore.setSelectedRegister(register); // Set the selected register in the register store
  showModal.value = false; // Close the modal
};
  let isEditing = ref(false);
  
  // Function to update client information in the store
  const updateClientInfo = debounce(() => {
    cartStore.updateClientInfo({
      firstName: firstName.value,
      lastName: lastName.value,
      email: clientEmail.value
    });
  }, 1000); // 1 second debounce
  
  // Function to start editing
  const startEditing = () => {
    isEditing.value = true;
  };
  
  // Function to stop editing and update the store
  const stopEditing = () => {
    isEditing.value = false;
    updateClientInfo();
  };
  
  // Sync client information from the store
  watchEffect(() => {
    if (!isEditing.value) {
      if (clientInfo.value.firstName !== firstName.value) {
        firstName.value = clientInfo.value.firstName;
      }
      if (clientInfo.value.lastName !== lastName.value) {
        lastName.value = clientInfo.value.lastName;
      }
      if (clientInfo.value.email !== clientEmail.value) {
        clientEmail.value = clientInfo.value.email;
      }
    }
  });
  
  // Watcher to clear client information and cart when the cart is cleared
  watch(items, (newItems) => {
    if (newItems.length === 0) {
      firstName.value = '';
      lastName.value = '';
      clientEmail.value = '';
    }
  });
  
  // Method to clear client information and cart
  const clearClientAndCart = () => {
    firstName.value = '';
    lastName.value = '';
    clientEmail.value = '';
    cartStore.clearCart();
  };

  </script>